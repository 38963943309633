<template>
  <div class="news-detail">
    <div style="height: 80px"></div>
    <banner title="新闻中心" subTitle="News Center" />
    <div class="body-box">
      <div class="c-width cf w1200">
        <div class="path">首页 > 新闻中心 > 详情</div>
        <div class="l-box fl">
          <div class="l-context">
            <h3>{{data[index].newsList[itemIndex].name}}</h3>
            <div class="date">
              发表于：{{data[index].newsList[itemIndex].time}}.{{data[index].newsList[itemIndex].day}}   浏览人数：{{data[index].newsList[itemIndex].look}}
            </div>
            <img :src="data[index].newsList[itemIndex].src" alt="">
            <div v-html="data[index].newsList[itemIndex].context"></div>
          </div>
          <div class="other-page cf">
              <p class="fl" @click="last">上一篇：<span v-if="itemIndex > 0">{{data[index].newsList[itemIndex-1].name}}</span> <span v-if="itemIndex == 0">没有上一篇</span></p>
              <p class="fr" @click="next">下一篇：<span v-if="itemIndex < data[index].newsList.length-1">{{data[index].newsList[itemIndex].name}}</span> <span v-if="itemIndex == data[index].newsList.length-1">没有更多了</span></p>
          </div>
        </div>
        <div class="fr r-menu"> 
            <h6>相关资讯</h6>
            <div class="item" v-for="(item,index) in data[index].newsList" :key='item.id' @click="selItem(index)">{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import data from "../../news/data/data";
import banner from "@/components/banner";
export default {
  components: {
    banner,
  },
  data() {
    return {
      data: data,
      fid: 1,
      index: 0,
      itemIndex: 0,
    };
  },
  created() {
    this.index = this.$route.query.index;
    this.itemIndex = this.$route.query.itemIndex;
  },
  methods: {
    selItem:function(index){
      console.log(index)
      this.itemIndex  = index;
    },
    last:function(){
      if(this.itemIndex == 0){
        return
      }else{
        this.itemIndex --;
      }
    },
    next:function(){
      if(this.itemIndex < data[this.index].newsList.length-1){
          this.itemIndex ++
      }else{
          return
      }
   
    }
  },
};
</script>
<style scoped>
@import "../style.css";
</style>